import {
	CUSTOMER_LIST_REQUEST,
	CUSTOMER_LIST_SUCCESS,
	CUSTOMER_LIST_FAILED,
	CUSTOMER_UPDATE_REQUEST,
	CUSTOMER_UPDATE_SUCCESS,
	CUSTOMER_UPDATE_FAILED,
	CUSTOMER_STATE_CHANGE,
	CUSTOMER_IMPORT_REQUEST,
	CUSTOMER_IMPORT_SUCCESS,
	CUSTOMER_IMPORT_FAILED,
	CUSTOMER_IMPORT_UNIT_MIX_SAVE_REQUEST,
	CUSTOMER_IMPORT_UNIT_MIX_SAVE_SUCCESS,
	CUSTOMER_IMPORT_UNIT_MIX_SAVE_FAILED,
	CUSTOMER_IMPORT_IMAGE_REQUEST,
	CUSTOMER_IMPORT_IMAGE_SUCCESS,
	CUSTOMER_IMPORT_IMAGE_FAILED,
	CUSTOMER_GET_IMAGE_REQUEST,
	CUSTOMER_GET_IMAGE_SUCCESS,
	CUSTOMER_GET_IMAGE_FAILED,
	CUSTOMER_CONTRACT_ADDED_LIST_REQUEST,
	CUSTOMER_CONTRACT_ADDED_LIST_SUCCESS,
	CUSTOMER_CONTRACT_ADDED_LIST_FAILED,
	CUSTOMER_UNIT_DETAIL_REQUEST,
	CUSTOMER_UNIT_DETAIL_SUCCESS,
	CUSTOMER_UNIT_DETAIL_FAILED,
	CUSTOMER_ORDER_DETAIL_REQUEST,
	CUSTOMER_ORDER_DETAIL_SUCCESS,
	CUSTOMER_ORDER_DETAIL_FAILED,
	SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST,
	SAVE_CUSTOMER_CONTRACT_DETAILS_SUCCESS,
	SAVE_CUSTOMER_CONTRACT_DETAILS_FAILED,
	CUSTOMER_EXISTING_ORDER_LIST_REQUEST,
	CUSTOMER_EXISTING_ORDER_LIST_SUCCESS,
	CUSTOMER_EXISTING_ORDER_LIST_FAILED,
	CUSTOMER_UNIT_MIX_LIST_REQUEST,
	CUSTOMER_UNIT_MIX_LIST_SUCCESS,
	CUSTOMER_UNIT_MIX_LIST_FAILED,
	CUSTOMER_UPDATE_UNIT_MIX_REQUEST,
	CUSTOMER_UPDATE_UNIT_MIX_SUCCESS,
	CUSTOMER_UPDATE_UNIT_MIX_FAILED,
	CUSTOMER_ORDER_UNIT_CHANGE_REQUEST,
	CUSTOMER_ORDER_UNIT_CHANGE_SUCCESS,
	CUSTOMER_ORDER_UNIT_CHANGE_FAILED,
	CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST,
	CUSTOMER_ORDER_UNIT_TYPE_CHANGE_SUCCESS,
	CUSTOMER_ORDER_UNIT_TYPE_CHANGE_FAILED,
	CUSTOMER_DELETE_UNIT_TYPE_REQUEST,
	CUSTOMER_DELETE_UNIT_TYPE_SUCCESS,
	CUSTOMER_DELETE_UNIT_TYPE_FAILED,
	SAVE_SELECTED_EXISTING_ORDER_REQUEST,
	SAVE_SELECTED_EXISTING_ORDER_SUCCESS,
	SAVE_SELECTED_EXISTING_ORDER_FAILED,
	CUSTOMER_ORDER_RESCHEDULE_REQUEST,
	CUSTOMER_ORDER_RESCHEDULE_SUCCESS,
	CUSTOMER_ORDER_RESCHEDULE_FAILED,
	CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST,
	CUSTOMER_ORDER_RESCHEDULE_DATE_SUCCESS,
	CUSTOMER_ORDER_RESCHEDULE_DATE_FAILED,
	CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST,
	CUSTOMER_ORDER_ADD_ON_DETAILS_SUCCESS,
	CUSTOMER_ORDER_ADD_ON_DETAILS_FAILED,
	CUSTOMER_ORDER_ADDON_SAVE_REQUEST,
	CUSTOMER_ORDER_ADDON_SAVE_SUCCESS,
	CUSTOMER_ORDER_ADDON_SAVE_FAILED,
	GET_SHORTAGE_REASON_REQUEST,
	GET_SHORTAGE_REASON_SUCCESS,
	GET_SHORTAGE_REASON_FAILED,
	SAVE_ORDER_SHORTAGE_DETAIL_REQUEST,
	SAVE_ORDER_SHORTAGE_DETAIL_SUCCESS,
	SAVE_ORDER_SHORTAGE_DETAIL_FAILED,
	UPDATE_SHORTAGE_REASON_REQUEST,
	UPDATE_SHORTAGE_REASON_SUCCESS,
	UPDATE_SHORTAGE_REASON_FAILED,
	GET_QUALITY_REASON_REQUEST,
	GET_QUALITY_REASON_SUCCESS,
	GET_QUALITY_REASON_FAILED,
	GET_QUALITY_ORDER_REQUEST,
	GET_QUALITY_ORDER_SUCCESS,
	GET_QUALITY_ORDER_FAILED,
	UPDATE_QUALITY_REASON_REQUEST,
	UPDATE_QUALITY_REASON_SUCCESS,
	UPDATE_QUALITY_REASON_FAILED,
	UPDATE_QUALITY_DETAIL_REQUEST,
	UPDATE_QUALITY_DETAIL_SUCCESS,
	UPDATE_QUALITY_DETAIL_FAILED
} from "../../../_utils/constants/Customer";

import { 
	SAVE_QUALITY_ORDER_DETAILS_REQUEST,
	SAVE_QUALITY_ORDER_DETAILS_SUCCESS,
	SAVE_QUALITY_ORDER_DETAILS_FAILED,
	SAVE_ORDER_QUALITY_DETAIL_REQUEST,
	SAVE_ORDER_QUALITY_DETAIL_SUCCESS,
	SAVE_ORDER_QUALITY_DETAIL_FAILED,
} from "../../../_utils/constants/PricingContract";

const initialState = {
	customerList: [],
	totalCount: 0,
	isLoading: false,
	isSuccess: false,
	isCreated: false,
	isDeleted: false,
	isEdited: false,
	importedData: [],
	isImported: false,
	saveImportedData: [],
	isSaveImportedData: false,
	isImageDataLoaded: true,
	imageList: [],
	unitMixData: [],
	customerContractAddedList: [],
	totalContractCount: 0,
	customerOrderDetails: [],
	customerSaveOrderDetails: [],
	customerSaveContractDetails: [],
	existingOrderList: [],
	errors: {},
	customerUnitMixList: [],
	customerUpdatedUnitMix: [],
	customerOrderUnit: [],
	customerOrderUnitType: [],
	customerDeletedUnitType: [],
	savedExistingOrder: [],
	isDateRescheduled: [],
	orderRescheduledDetails: [],
	orderAddOnDetails: [],
	saveOrderAddOnDetails: [],
	shortageReason:[],
	saveOrderShortage:[],
	updateShortageReason:[],
	qualityReasons:[],
	saveOrderQualityDetailData:[],
	OrderQualityDetail:[],
	updatedQualityReason: [],
	updatedQualityDetail: [],
	qualityChangeJobDetails: [],
	isOrderLoading: false
};

const CustomerInfoReducer = (state = initialState, action) => {
	switch (action.type) {

		case CUSTOMER_LIST_REQUEST:
			return {
				...state,
				isLoading: true,
				isSuccess: false,
				errors: {}
			}
		case CUSTOMER_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerList: decryptedData.result,
				totalCount: decryptedData.TotalCount,
				isLoading: false,
				isSuccess: true,
				errors: {}
			}
		case CUSTOMER_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				errors: action.payload
			}

		case CUSTOMER_UPDATE_REQUEST:
			return {
				...state,
				isLoading: true,
				isEdited: false,
				errors: {}
			}
		case CUSTOMER_UPDATE_SUCCESS:
			return {
				...state,
				isLoading: false,
				isEdited: true,
				errors: {}
			}
		case CUSTOMER_UPDATE_FAILED:
			return {
				...state,
				isLoading: false,
				isEdited: false,
				errors: action.payload
			}

		case CUSTOMER_IMPORT_REQUEST:
			return {
				...state,
				isLoading: true,
				isImported: false,
				errors: {}
			}
		case CUSTOMER_IMPORT_SUCCESS:
			var decryptedImportData = action.payload;
			return {
				...state,
				importedData: decryptedImportData.result,
				isLoading: false,
				isImported: true,
				errors: {}
			}
		case CUSTOMER_IMPORT_FAILED:
			return {
				...state,
				isLoading: false,
				isImported: false,
				errors: action.payload
			}

		case CUSTOMER_IMPORT_UNIT_MIX_SAVE_REQUEST:
			return {
				...state,
				isLoading: true,
				isSaveImportedData: false,
				errors: {}
			}
		case CUSTOMER_IMPORT_UNIT_MIX_SAVE_SUCCESS:
			var decryptedSaveImportData = action.payload;
			return {
				...state,
				saveImportedData: decryptedSaveImportData,
				isLoading: false,
				isSaveImportedData: true,
				errors: {}
			}
		case CUSTOMER_IMPORT_UNIT_MIX_SAVE_FAILED:
			return {
				...state,
				isLoading: false,
				isSaveImportedData: false,
				errors: action.payload
			}
		case CUSTOMER_IMPORT_IMAGE_REQUEST:
			return {
				...state,
				isLoading: true,
				isSuccess: false,
				errors: {}
			}
		case CUSTOMER_IMPORT_IMAGE_SUCCESS:
			var decryptedImageData = action.payload;
			return {
				...state,
				unitMixData: decryptedImageData.result,
				isLoading: false,
				isSuccess: true,
				errors: {}
			}
		case CUSTOMER_IMPORT_IMAGE_FAILED:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				errors: action.payload
			}

		case CUSTOMER_GET_IMAGE_REQUEST:
			return {
				...state,
				isLoading: true,
				isSuccess: false,
				isImageDataLoaded: true,
				errors: {}
			}
		case CUSTOMER_GET_IMAGE_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				imageList: decryptedData.result,
				isLoading: false,
				isSuccess: true,
				isImageDataLoaded: false,
				errors: {}
			}
		case CUSTOMER_GET_IMAGE_FAILED:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				isImageDataLoaded: false,
				errors: action.payload
			}
		case CUSTOMER_CONTRACT_ADDED_LIST_REQUEST:
			return {
				...state,
				isLoading: true,
				isSuccess: false,
				errors: {}
			}
		case CUSTOMER_CONTRACT_ADDED_LIST_SUCCESS:
			var decryptedContractAddedData = action.payload;
			return {
				...state,
				customerContractAddedList: decryptedContractAddedData.result,
				totalContractCount: decryptedContractAddedData.TotalCount,
				isLoading: false,
				isSuccess: true,
				errors: {}
			}
		case CUSTOMER_CONTRACT_ADDED_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				errors: action.payload
			}
		case CUSTOMER_UNIT_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true,
				isOrderLoading: true,
				errors: {}
			}
		case CUSTOMER_UNIT_DETAIL_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerOrderDetails: decryptedData.result,
				isOrderLoading: false,
				isLoading: false,
				errors: {}
			}
		case CUSTOMER_UNIT_DETAIL_FAILED:
			return {
				...state,
				isLoading: false,
				isOrderLoading: false,
				errors: action.payload
			}
		case CUSTOMER_ORDER_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case CUSTOMER_ORDER_DETAIL_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerSaveOrderDetails: decryptedData.result,
				isLoading: false,
				errors: {}
			}
		case CUSTOMER_ORDER_DETAIL_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case SAVE_CUSTOMER_CONTRACT_DETAILS_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerSaveContractDetails: decryptedData.result,
				isLoading: false,
				errors: {}
			}
		case SAVE_CUSTOMER_CONTRACT_DETAILS_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case CUSTOMER_EXISTING_ORDER_LIST_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case CUSTOMER_EXISTING_ORDER_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				existingOrderList: decryptedData.result,
				totalCount: decryptedData.TotalCount,
				isLoading: false,
				errors: {}
			}
		case CUSTOMER_EXISTING_ORDER_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case CUSTOMER_UNIT_MIX_LIST_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case CUSTOMER_UNIT_MIX_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerUnitMixList: decryptedData.result,
				isLoading: false,
				errors: {}
			}
		case CUSTOMER_UNIT_MIX_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case CUSTOMER_UPDATE_UNIT_MIX_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case CUSTOMER_UPDATE_UNIT_MIX_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerUpdatedUnitMix: decryptedData.result,
				isLoading: false,
				errors: {}
			}
		case CUSTOMER_UPDATE_UNIT_MIX_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case CUSTOMER_ORDER_UNIT_CHANGE_REQUEST:
			return {
				...state,
				errors: {}
			}
		case CUSTOMER_ORDER_UNIT_CHANGE_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerOrderUnit: decryptedData.result,
				errors: {}
			}
		case CUSTOMER_ORDER_UNIT_CHANGE_FAILED:
			return {
				...state,
				errors: action.payload
			}
		case CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST:
			return {
				...state,
				errors: {}
			}
		case CUSTOMER_ORDER_UNIT_TYPE_CHANGE_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerOrderUnitType: decryptedData.result,
				errors: {}
			}
		case CUSTOMER_ORDER_UNIT_TYPE_CHANGE_FAILED:
			return {
				...state,
				errors: action.payload
			}
		case CUSTOMER_DELETE_UNIT_TYPE_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case CUSTOMER_DELETE_UNIT_TYPE_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				isLoading: false,
				customerDeletedUnitType: decryptedData.result,
				errors: {}
			}
		case CUSTOMER_DELETE_UNIT_TYPE_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case SAVE_SELECTED_EXISTING_ORDER_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case SAVE_SELECTED_EXISTING_ORDER_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				savedExistingOrder: decryptedData.result,
				isLoading: false,
				errors: {}
			}
		case SAVE_SELECTED_EXISTING_ORDER_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case CUSTOMER_ORDER_RESCHEDULE_REQUEST:
			return {
				...state,
				errors: {}
			}
		case CUSTOMER_ORDER_RESCHEDULE_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				isDateRescheduled: decryptedData.result,
				errors: {}
			}
		case CUSTOMER_ORDER_RESCHEDULE_FAILED:
			return {
				...state,
				errors: action.payload
			}
		case CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case CUSTOMER_ORDER_RESCHEDULE_DATE_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				orderRescheduledDetails: decryptedData.result,
				isLoading: false,
				errors: {}
			}
		case CUSTOMER_ORDER_RESCHEDULE_DATE_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case CUSTOMER_ORDER_ADD_ON_DETAILS_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				orderAddOnDetails: decryptedData.result,
				isLoading: false,
				errors: {}
			}
		case CUSTOMER_ORDER_ADD_ON_DETAILS_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case CUSTOMER_ORDER_ADDON_SAVE_REQUEST:
			return {
				...state,
				errors: {}
			}
		case CUSTOMER_ORDER_ADDON_SAVE_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				saveOrderAddOnDetails: decryptedData.result,
				errors: {}
			}
		case CUSTOMER_ORDER_ADDON_SAVE_FAILED:
			return {
				...state,
				errors: action.payload
			}
			
		case GET_SHORTAGE_REASON_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case GET_SHORTAGE_REASON_SUCCESS:
			return {
				...state,
				shortageReason: action.payload,
				isLoading: false,
				errors: {}
			}
		case GET_SHORTAGE_REASON_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}

		case SAVE_ORDER_SHORTAGE_DETAIL_REQUEST : 
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case SAVE_ORDER_SHORTAGE_DETAIL_SUCCESS:
			return {
				...state,
				saveOrderShortage: action.payload,
				isLoading: false,
				errors: {}
			}
		case SAVE_ORDER_SHORTAGE_DETAIL_FAILED :
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		
		case UPDATE_SHORTAGE_REASON_REQUEST : 
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case UPDATE_SHORTAGE_REASON_SUCCESS:
			return {
				...state,
				updateShortageReason: action.payload,
				isLoading: false,
				errors: {}
			}
		case UPDATE_SHORTAGE_REASON_FAILED :
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		
		case GET_QUALITY_REASON_REQUEST : 
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case GET_QUALITY_REASON_SUCCESS:
			return {
				...state,
				qualityReasons: action.payload,
				isLoading: false,
				errors: {}
			}
		case GET_QUALITY_REASON_FAILED :
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}

		case SAVE_QUALITY_ORDER_DETAILS_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case SAVE_QUALITY_ORDER_DETAILS_SUCCESS:
			return {
				...state,
				saveOrderQualityDetailData: action.payload,
				isLoading: false,
				errors: {}
			}
		case SAVE_QUALITY_ORDER_DETAILS_FAILED :
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}

		case GET_QUALITY_ORDER_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case GET_QUALITY_ORDER_SUCCESS:
			return {
				...state,
				OrderQualityDetail: action.payload.result,
				isLoading: false,
				errors: {}
			}
		case GET_QUALITY_ORDER_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case UPDATE_QUALITY_REASON_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case UPDATE_QUALITY_REASON_SUCCESS:
			return {
				...state,
				updatedQualityReason: action.payload,
				isLoading: false,
				errors: {}
			}
		case UPDATE_QUALITY_REASON_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case UPDATE_QUALITY_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case UPDATE_QUALITY_DETAIL_SUCCESS:
			return {
				...state,
				updatedQualityDetail: action.payload,
				isLoading: false,
				errors: {}
			}
		case UPDATE_QUALITY_DETAIL_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case SAVE_ORDER_QUALITY_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true,
				errors: {}
			}
		case SAVE_ORDER_QUALITY_DETAIL_SUCCESS:
			return {
				...state,
				qualityChangeJobDetails: action.payload,
				isLoading: false,
				errors: {}
			}
		case SAVE_ORDER_QUALITY_DETAIL_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
			
		case CUSTOMER_STATE_CHANGE:
		return {
			...state,
			customerList: [],
			totalCount: 0,
			isLoading: false,
			isSuccess: false,
			isCreated: false,
			isDeleted: false,
			isEdited: false,
			importedData: [],
			isImported: false,
			saveImportedData: [],
			isSaveImportedData: false,
			imageList: [],
			unitMixData: [],
			customerContractAddedList: [],
			totalContractCount: 0,
			errors: {},
			customerUpdatedUnitMix: []
		}

	};
	return state;
};

export default CustomerInfoReducer;