import React, { Fragment, useState, useMemo, useEffect, useContext } from "react";
import Icon from "react-icons-kit";
import { ic_done_outline } from 'react-icons-kit/md/ic_done_outline';
import '../../../assets/css/Contacts.css';
import _ from "lodash";
import { Table, Form } from "react-bootstrap";
import Input from "../../elements/CustomInput";
import Select from "../../elements/CustomSelect";
import InfiniteScroll from "react-infinite-scroll-component";
import Checkbox from "../../elements/CustomCheckBox";
import PropertyList from "../placeOrder/models/PropertyList";
import {Context} from "../../../App";
import { useNavigate } from "react-router-dom";

const CustomerList = ({
	customerList,
	totalCount,
	setPaginate,
	paginate,
	listParamState,
  handleFilterChange,
	storeList,
  checkGroupContractState,
  groupContractCustomerId,
  handleGroupContractCheckbox,
  handlePropertyClick,
  isPCAddedToProperty,
  handlePropertySelection
}) => {
  
  const navigate = useNavigate();
  const {pushervalue} = useContext(Context);
  const path = window.location.pathname.substring(1);

	// states declaration
  const [items, setItems] = useState([]);
  const [isMore, setIsMore] = useState([]);
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const [orderingUrl, setIsOrderingUrl] = useState();
  const [processStart, setProcessStart] = useState(false);
  const estimator = "sale-customer-info"

	// function called to fetch more data, pagination
	const fetchMoreData = () => {
    setTimeout(() => {
      setPaginate(paginate + 10)
    }, 1500);
  };

  useEffect(() => {
    if (path === estimator) {
      localStorage.setItem('showSeamCutPlan', JSON.parse(false))
    } else {
      localStorage.setItem('showSeamCutPlan', JSON.parse(true))
    }
  }, [path])

  // function to check pagination mor to load data or not
  useEffect(() => {
    setItems(customerList);
		if (_.isEqual(totalCount, customerList.length)) {
      setIsMore(false);
    } else {
      setIsMore(true);
    }
  }, [customerList])
  
  // open modal for property list
  const handlePropertyModalOpen = () => {
    setIsPropertyModalOpen(true)
  }

  // close modal for property list
  const handlePropertyModalClose = () => {
    setIsPropertyModalOpen(false)
  }

  useMemo(() => {
    if (processStart) {
      let authValue = JSON.parse(localStorage.getItem('geoErp'))
      let channel = pushervalue.subscribe(authValue[0]?.uniqueChannelName);
      localStorage.setItem('Existing_Orders', JSON.parse(false))
      localStorage.setItem('Existing-Orders-Page', JSON.parse(false))
      localStorage.removeItem('isQuality')
      localStorage.removeItem('quality-order')
      localStorage.removeItem('quality-JobNum')
      localStorage.removeItem('quality-UnitId')
      localStorage.setItem('Existing_Orders_value', null)
      channel.bind("update-order-status", (data) => {
        if(data?.PusherTrigger === "installation-detail-status") {
          navigate(`/place-order/order-confirmation/contactInfoId=${localStorage.getItem("place-order-contactInfoId")}/unitId=${data?.UnitId}/jobNum=${data?.JobNum}`)
          handlePropertyModalClose()
        }
        if(data?.PusherTrigger === "close-ordering-popup") {
          window.location.reload();
        }
      });
    }
  }, [processStart]);

	// setting new list data using memo
	const data = useMemo(() => items, [items]);

	// setting store list data using memo
	const storeData = useMemo(() => {
    var stores = [{ value: 0, label: "All" }];
    return stores.concat(storeList);
  }, [storeList])

  // handle click property name
  const handlePropertyClickForOrdering = (customer) => {
    setProcessStart(true)
    let url = customer?.crossDomainLink
    localStorage.setItem("place-order-contactInfoId", customer?.contactInfoId)
    let modified_url = url.replace("select-plan", "property-options")
    let authValue = JSON.parse(localStorage.getItem('geoErp'))
    let uniqueChannelName = modified_url + '&uniqueChannelName=' + authValue[0].uniqueChannelName
    setIsOrderingUrl(uniqueChannelName)
    handlePropertyModalOpen()
  }

  //  handle property selection
  const handlePropertySelect = (e) => {
    handlePropertySelection(e)
  }

	return (
		<Fragment>
      <Table bordered responsive>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={isMore}
          loader={
            <div className="data-loading">
              <img src="https://64.media.tumblr.com/695ce9a82c8974ccbbfc7cad40020c62/tumblr_o9c9rnRZNY1qbmm1co1_1280.gifv" />
              <span>Load More</span>
            </div>
          }
          height={groupContractCustomerId ? window.innerHeight : null}
          >
          <thead>
            <tr>
              {checkGroupContractState === true ?
                (<th>
                  Check to Add
                  <Form.Check type="checkbox" onChange={(e)=>handlePropertySelect(e)} ids="IsContractProperty" />
                </th>)
              : ""}
              {checkGroupContractState === true ?
                (<th>
                  Contract
                </th>)
              : ""}
							<th>
                <Input label={`Salesperson`} ids={`salesPerson`} value={listParamState.salesPerson} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`Property Name`} ids={`shipToPropertyName`} value={listParamState.shipToPropertyName} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`Management Company`} ids={`shipToManagementCompany`} value={listParamState.shipToManagementCompany} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`Address`} ids={`shipToAddress`} value={listParamState.shipToAddress} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`City`} ids={`shipToCity`} value={listParamState.shipToCity} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`State`} ids={`shipToState`} value={listParamState.shipToState} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Select label={`Store Name`} result={storeData} ids="StoreId" value={listParamState.StoreId} handleChange={handleFilterChange} validate={false} />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) ? _.map(data, (cust) => {
              return (
                <tr key={cust.contactInfoId}>
                  {checkGroupContractState === true ?
                    (<td>
                      <Checkbox 
                        ids="materialId" 
                        validate={false}
                        handleChange={(e)=>handleGroupContractCheckbox(e, cust.contactInfoId, cust)}
                        value={groupContractCustomerId.includes(cust.contactInfoId)} 
                      />
                    </td>) : "" 
                  }
                  {checkGroupContractState === true ?
                    (cust?.isPCAddedToProperty ? <td><Icon size={18} icon={ic_done_outline} /></td> : <td></td>) : "" 
                  }
									<td>
                    {
                      !handlePropertyClick ?
                        <div>
                          {checkGroupContractState === true
                            ? <div>{_.get(cust, "salesPerson", "")}</div>
                            : <a href={`customer-list/customer-detail/${cust.contactInfoId}`}>{_.get(cust, "salesPerson", "")}</a>
                          }
                        </div>
                      : <div className="link" onClick={e=>handlePropertyClickForOrdering(cust)}>{_.get(cust, "salesPerson", "")}</div>
                    }
                  </td>
                  <td>
                  {
                    !handlePropertyClick ?
                      <div>
                        {checkGroupContractState === true
                          ? <div>{_.get(cust, "shipToPropertyName", "")}</div>
                          : <a href={`customer-list/customer-detail/${cust.contactInfoId}`}>{_.get(cust, "shipToPropertyName", "")}</a>
                        }
                      </div>
                    : <div className="link" onClick={e=>handlePropertyClickForOrdering(cust)}>{_.get(cust, "shipToPropertyName", "")}</div>
                  }
                  </td>
                  <td>
                    {
                      !handlePropertyClick ?
                        <div>
                          {checkGroupContractState === true
                            ? <div>{_.get(cust, "shipToManagementCompany", "")}</div>
                            : <a href={`customer-list/customer-detail/${cust.contactInfoId}`}>{_.get(cust, "shipToManagementCompany", "")}</a>
                          }
                        </div>
                      : <div className="link" onClick={e=>handlePropertyClickForOrdering(cust)}>{_.get(cust, "shipToManagementCompany", "")}</div>
                    }
                  </td>
                  <td>
                    {
                      !handlePropertyClick ?
                        <div>
                          {checkGroupContractState === true
                            ? <div>{_.get(cust, "shipToAddress", "")}</div>
                            : <a href={`customer-list/customer-detail/${cust.contactInfoId}`}>{_.get(cust, "shipToAddress", "")}</a>
                          }
                        </div>
                      : <div className="link" onClick={e=>handlePropertyClickForOrdering(cust)}>{_.get(cust, "shipToAddress", "")}</div>
                    }
                  </td>
                  <td>
                    {
                      !handlePropertyClick ?
                        <div>
                          {checkGroupContractState === true
                            ? <div>{_.get(cust, "shipToCity", "")}</div>
                            : <a href={`customer-list/customer-detail/${cust.contactInfoId}`}>{_.get(cust, "shipToCity", "")}</a>
                          }
                        </div>
                      : <div className="link" onClick={e=>handlePropertyClickForOrdering(cust)}>{_.get(cust, "shipToCity", "")}</div>
                    }
                  </td>
                  <td>
                    {
                      !handlePropertyClick ?
                        <div>
                          {checkGroupContractState === true
                            ? <div>{_.get(cust, "shipToState", "")}</div>
                            : <a href={`customer-list/customer-detail/${cust.contactInfoId}`}>{_.get(cust, "shipToState", "")}</a>
                          }
                        </div>
                      : <div className="link" onClick={e=>handlePropertyClickForOrdering(cust)}>{_.get(cust, "shipToState", "")}</div>
                    }
                  </td>
                  <td>
                    {
                      !handlePropertyClick ?
                        <div>
                          {checkGroupContractState === true
                            ? <div>{_.get(cust, "storeName", "")}</div>
                            : <a href={`customer-list/customer-detail/${cust.contactInfoId}`}>{_.get(cust, "storeName", "")}</a>
                          }
                        </div>
                      : <div className="link" onClick={e=>handlePropertyClickForOrdering(cust)}>{_.get(cust, "storeName", "")}</div>
                    }
                  </td>
                </tr>
              )
            }) : (
              <tr>
                <td colSpan={`18`}>No Data Found</td>
              </tr>
            )}
          </tbody>
        </InfiniteScroll>
      </Table>
      {isPropertyModalOpen && 
        <PropertyList
          handlePropertyModalOpen={handlePropertyModalOpen}
          handlePropertyModalClose={handlePropertyModalClose}
          orderingUrl={orderingUrl}
        />
      }
		</Fragment>
	)
}

export default CustomerList;