export const CUSTOMER_LIST_REQUEST = "@CUSTOMER_LIST_REQUEST";
export const CUSTOMER_LIST_SUCCESS = "@CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_FAILED = "@CUSTOMER_LIST_FAILED";

export const CUSTOMER_UPDATE_REQUEST = "@CUSTOMER_UPDATE_REQUEST";
export const CUSTOMER_UPDATE_SUCCESS = "@CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAILED = "@CUSTOMER_UPDATE_FAILED";

export const CUSTOMER_IMPORT_REQUEST = "@CUSTOMER_IMPORT_REQUEST";
export const CUSTOMER_IMPORT_SUCCESS = "@CUSTOMER_IMPORT_SUCCESS";
export const CUSTOMER_IMPORT_FAILED = "@CUSTOMER_IMPORT_FAILED";

export const CUSTOMER_IMPORT_UNIT_MIX_SAVE_REQUEST = "@CUSTOMER_IMPORT_UNIT_MIX_SAVE_REQUEST";
export const CUSTOMER_IMPORT_UNIT_MIX_SAVE_SUCCESS = "@CUSTOMER_IMPORT_UNIT_MIX_SAVE_SUCCESS";
export const CUSTOMER_IMPORT_UNIT_MIX_SAVE_FAILED = "@CUSTOMER_IMPORT_UNIT_MIX_SAVE_FAILED";

export const CUSTOMER_UNIT_MIX_LIST_REQUEST = "@CUSTOMER_UNIT_MIX_LIST_REQUEST";
export const CUSTOMER_UNIT_MIX_LIST_SUCCESS = "@CUSTOMER_UNIT_MIX_LIST_SUCCESS";
export const CUSTOMER_UNIT_MIX_LIST_FAILED = "@CUSTOMER_UNIT_MIX_LIST_FAILED";

export const CUSTOMER_DELETE_UNIT_TYPE_REQUEST = "@CUSTOMER_DELETE_UNIT_TYPE_REQUEST";
export const CUSTOMER_DELETE_UNIT_TYPE_SUCCESS = "@CUSTOMER_DELETE_UNIT_TYPE_SUCCESS";
export const CUSTOMER_DELETE_UNIT_TYPE_FAILED = "@CUSTOMER_DELETE_UNIT_TYPE_FAILED";

export const CUSTOMER_UPDATE_UNIT_MIX_REQUEST = "@CUSTOMER_UPDATE_UNIT_MIX_REQUEST";
export const CUSTOMER_UPDATE_UNIT_MIX_SUCCESS = "@CUSTOMER_UPDATE_UNIT_MIX_SUCCESS";
export const CUSTOMER_UPDATE_UNIT_MIX_FAILED = "@CUSTOMER_UPDATE_UNIT_MIX_FAILED";

export const CUSTOMER_IMPORT_IMAGE_REQUEST = "@CUSTOMER_IMPORT_IMAGE_REQUEST";
export const CUSTOMER_IMPORT_IMAGE_SUCCESS = "@CUSTOMER_IMPORT_IMAGE_SUCCESS";
export const CUSTOMER_IMPORT_IMAGE_FAILED = "@CUSTOMER_IMPORT_IMAGE_FAILED";

export const CUSTOMER_GET_IMAGE_REQUEST = "@CUSTOMER_GET_IMAGE_REQUEST";
export const CUSTOMER_GET_IMAGE_SUCCESS = "@CUSTOMER_GET_IMAGE_SUCCESS";
export const CUSTOMER_GET_IMAGE_FAILED = "@CUSTOMER_GET_IMAGE_FAILED";

export const CUSTOMER_CONTRACT_ADDED_LIST_REQUEST = "@CUSTOMER_CONTRACT_ADDED_LIST_REQUEST";
export const CUSTOMER_CONTRACT_ADDED_LIST_SUCCESS = "@CUSTOMER_CONTRACT_ADDED_LIST_SUCCESS";
export const CUSTOMER_CONTRACT_ADDED_LIST_FAILED = "@CUSTOMER_CONTRACT_ADDED_LIST_FAILED";

export const CUSTOMER_UNIT_DETAIL_REQUEST = "@CUSTOMER_UNIT_DETAIL_REQUEST";
export const CUSTOMER_UNIT_DETAIL_SUCCESS = "@CUSTOMER_UNIT_DETAIL_SUCCESS";
export const CUSTOMER_UNIT_DETAIL_FAILED = "@CUSTOMER_UNIT_DETAIL_FAILED";

export const CUSTOMER_ORDER_DETAIL_REQUEST = "@CUSTOMER_ORDER_DETAIL_REQUEST";
export const CUSTOMER_ORDER_DETAIL_SUCCESS = "@CUSTOMER_ORDER_DETAIL_SUCCESS";
export const CUSTOMER_ORDER_DETAIL_FAILED = "@CUSTOMER_ORDER_DETAIL_FAILED";

export const CUSTOMER_STATE_CHANGE = "@CUSTOMER_STATE_CHANGE";

export const SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST = "@SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST";
export const SAVE_CUSTOMER_CONTRACT_DETAILS_SUCCESS = "@SAVE_CUSTOMER_CONTRACT_DETAILS_SUCCESS";
export const SAVE_CUSTOMER_CONTRACT_DETAILS_FAILED = "@SAVE_CUSTOMER_CONTRACT_DETAILS_FAILED";

export const CUSTOMER_EXISTING_ORDER_LIST_REQUEST = "@CUSTOMER_EXISTING_ORDER_LIST_REQUEST";
export const CUSTOMER_EXISTING_ORDER_LIST_SUCCESS = "@CUSTOMER_EXISTING_ORDER_LIST_SUCCESS";
export const CUSTOMER_EXISTING_ORDER_LIST_FAILED = "@CUSTOMER_EXISTING_ORDER_LIST_FAILED";

export const CUSTOMER_ORDER_UNIT_CHANGE_REQUEST = "@CUSTOMER_ORDER_UNIT_CHANGE_REQUEST";
export const CUSTOMER_ORDER_UNIT_CHANGE_SUCCESS = "@CUSTOMER_ORDER_UNIT_CHANGE_SUCCESS";
export const CUSTOMER_ORDER_UNIT_CHANGE_FAILED = "@CUSTOMER_ORDER_UNIT_CHANGE_FAILED";

export const CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST = "@CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST";
export const CUSTOMER_ORDER_UNIT_TYPE_CHANGE_SUCCESS = "@CUSTOMER_ORDER_UNIT_TYPE_CHANGE_SUCCESS";
export const CUSTOMER_ORDER_UNIT_TYPE_CHANGE_FAILED = "@CUSTOMER_ORDER_UNIT_TYPE_CHANGE_FAILED";

export const CUSTOMER_ORDER_RESCHEDULE_REQUEST = "@CUSTOMER_ORDER_RESCHEDULE_REQUEST";
export const CUSTOMER_ORDER_RESCHEDULE_SUCCESS = "@CUSTOMER_ORDER_RESCHEDULE_SUCCESS";
export const CUSTOMER_ORDER_RESCHEDULE_FAILED = "@CUSTOMER_ORDER_RESCHEDULE_FAILED";

export const SAVE_SELECTED_EXISTING_ORDER_REQUEST = "@SAVE_SELECTED_EXISTING_ORDER_REQUEST";
export const SAVE_SELECTED_EXISTING_ORDER_SUCCESS = "@SAVE_SELECTED_EXISTING_ORDER_SUCCESS";
export const SAVE_SELECTED_EXISTING_ORDER_FAILED = "@SAVE_SELECTED_EXISTING_ORDER_FAILED";

export const CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST = "@CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST";
export const CUSTOMER_ORDER_RESCHEDULE_DATE_SUCCESS = "@CUSTOMER_ORDER_RESCHEDULE_DATE_SUCCESS";
export const CUSTOMER_ORDER_RESCHEDULE_DATE_FAILED = "@CUSTOMER_ORDER_RESCHEDULE_DATE_FAILED";

export const CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST = "@CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST";
export const CUSTOMER_ORDER_ADD_ON_DETAILS_SUCCESS = "@CUSTOMER_ORDER_ADD_ON_DETAILS_SUCCESS";
export const CUSTOMER_ORDER_ADD_ON_DETAILS_FAILED = "@CUSTOMER_ORDER_ADD_ON_DETAILS_FAILED";

export const CUSTOMER_ORDER_ADDON_SAVE_REQUEST = "@CUSTOMER_ORDER_ADDON_SAVE_REQUEST";
export const CUSTOMER_ORDER_ADDON_SAVE_SUCCESS = "@CUSTOMER_ORDER_ADDON_SAVE_SUCCESS";
export const CUSTOMER_ORDER_ADDON_SAVE_FAILED = "@CUSTOMER_ORDER_ADDON_SAVE_FAILED";

export const GET_SHORTAGE_REASON_REQUEST = "@GET_SHORTAGE_REASON_REQUEST";
export const GET_SHORTAGE_REASON_SUCCESS = "@GET_SHORTAGE_REASON_SUCCESS";
export const GET_SHORTAGE_REASON_FAILED = "@GET_SHORTAGE_REASON_FAILED";

export const SAVE_ORDER_SHORTAGE_DETAIL_REQUEST = "@SAVE_ORDER_SHORTAGE_DETAIL_REQUEST";
export const SAVE_ORDER_SHORTAGE_DETAIL_SUCCESS = "@SAVE_ORDER_SHORTAGE_DETAIL_SUCCESS";
export const SAVE_ORDER_SHORTAGE_DETAIL_FAILED  = "@SAVE_ORDER_SHORTAGE_DETAIL_FAILED";

export const UPDATE_SHORTAGE_REASON_REQUEST = "@UPDATE_SHORTAGE_REASON_REQUEST";
export const UPDATE_SHORTAGE_REASON_SUCCESS = "@UPDATE_SHORTAGE_REASON_SUCCESS";
export const UPDATE_SHORTAGE_REASON_FAILED = "@UPDATE_SHORTAGE_REASON_FAILED";

export const GET_QUALITY_REASON_REQUEST = "@GET_QUALITY_REASON_REQUEST"
export const GET_QUALITY_REASON_SUCCESS = "@GET_QUALITY_REASON_SUCCESS"
export const GET_QUALITY_REASON_FAILED = "@GET_QUALITY_REASON_FAILED"

export const GET_QUALITY_ORDER_REQUEST = "@GET_QUALITY_ORDER_REQUEST"
export const GET_QUALITY_ORDER_SUCCESS = "@GET_QUALITY_ORDER_SUCCESS"
export const GET_QUALITY_ORDER_FAILED = "@GET_QUALITY_ORDER_FAILED"

export const UPDATE_QUALITY_REASON_REQUEST = "@UPDATE_QUALITY_REASON_REQUEST";
export const UPDATE_QUALITY_REASON_SUCCESS = "@UPDATE_QUALITY_REASON_SUCCESS";
export const UPDATE_QUALITY_REASON_FAILED = "@UPDATE_QUALITY_REASON_FAILED";

export const UPDATE_QUALITY_DETAIL_REQUEST = "@UPDATE_QUALITY_DETAIL_REQUEST";
export const UPDATE_QUALITY_DETAIL_SUCCESS = "@UPDATE_QUALITY_DETAIL_SUCCESS";
export const UPDATE_QUALITY_DETAIL_FAILED = "@UPDATE_QUALITY_DETAIL_FAILED";